<template>
    <div>
        <v-img :src="src" 
            style="width:190px;" 
            @touchstart="active=true" 
            @touchend.stop.prevent="active=false;$emit('Click')"  
            @click.stop.prevent="$emit('Click')"
            class="mx-auto"> 
        </v-img>     
    </div>
</template>
<script>
export default {
    data () { return {
        active: false,
    }},
    computed: {
        src: function () { 
            return !this.active ? '/img/01imprimirVL.svg' : '/img/01imprimirvl2.svg'
        }
    },
}
</script>